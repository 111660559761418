import {http} from './config';
import stores from '../vuex';

export default {

    companyResume: (companyId) => {
        return http.get('api/v1/dashboard/resumoPorCompanhia', {headers: {'company-id': companyId}});
    },
    resumReserveByType: (allotmentId) => {
        return http.get('api/v1/dashboard/reservas-por-tipo', {headers: {'allotment-id': allotmentId}});
    },
    summaryLotsByAllotment: (allotmentId) => {
        return http.get('api/v1/dashboard/loteamento/resumo/lotes', {headers: {'allotment-id': allotmentId}});
    },
    summaryReservesByAllotment: (allotmentId) => {
        return http.get('api/v1/dashboard/loteamento/resumo/reservas', {headers: {'allotment-id': allotmentId}});
    },
    datasAnual: (companyId) => {
        return http.get(`api/v1/dashboard/progressoMovimentacoesPorCompanhia/yearInitial/yearFinal`, {headers: {'company-id': companyId}});
    },
    exportResumBrokerByAllotment: (allotmentId) => {
        stores.commit('changeLoading', true);
        return http.get(`api/v1/exportar/resumo-reservas-corretor-por-loteamento`, {
            headers: {'allotment-id': allotmentId},
            responseType: 'blob',
        }).then(resp => {
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'qtd_reservas_corretor.xlsx');
            link.click()
            stores.commit('changeLoading', false);
        }).error(() => {
            stores.commit('changeLoading', false);
        });

    },
    datasAnualReserve: (companyId, allotmentId, days = false) => {
        if (allotmentId) {
            return http.get(`api/v1/dashboard/progressoReservasPorCompanhia?days=${days}`, {
                headers: {
                    'company-id': companyId,
                    'allotment-id': allotmentId
                }
            });
        }
        return http.get(`api/v1/dashboard/progressoReservasPorCompanhia?days=${days}`, {headers: {'company-id': companyId}});
    },
    getReservesBrokersByAllotment: (allotmentId, type = '1', dayS = null, dayE = null) => {
        if (type === '2') {
            return http.get(`api/v1/dashboard/reservas/corretores-loteamento`, {
                headers: {
                    'allotment-id': allotmentId,
                    filter: 'total-reservas-confirmed',
                    dayStart: dayS ? dayS : '',
                    dayEnd: dayE ? dayE : '',
                }
            });
        }
        return http.get(`api/v1/dashboard/reservas/corretores-loteamento`, {
            headers: {
                'allotment-id': allotmentId,
                dayStart: dayS ? dayS : '',
                dayEnd: dayE ? dayE : '',
            }
        });
    },
    getReservesImobiByAllotment: (allotmentId, type = '1', dayS = null, dayE = null) => {
        if (type === '2') {
            return http.get(`api/v1/dashboard/reservas/imobiliarias-loteamento`, {
                headers: {
                    'allotment-id': allotmentId,
                    filter: 'total-reservas-confirmed',
                    dayStart: dayS ? dayS : '',
                    dayEnd: dayE ? dayE : '',
                }
            });
        }
        return http.get(`api/v1/dashboard/reservas/imobiliarias-loteamento`, {
            headers: {
                'allotment-id': allotmentId,
                dayStart: dayS ? dayS : '',
                dayEnd: dayE ? dayE : '',
            }
        });
    },
    totalStoredByCompany: () => {
        return http.get('api/v1/dashboard/drive/totalArmazenado');
    }
}
